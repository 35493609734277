/* eslint-disable no-unused-vars */
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import router from '@/router';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { configuration } from './aws-exports';
import { log } from '@/constants.js';

Amplify.configure(configuration);

// -------------------- AUTH --------------------------
// You can get the current config object
export const currentConfig = Auth.configure();

export const getJwtToken = async () => {
  let jwt;
  await Auth.currentSession().then(res => {
    const accessToken = res.getAccessToken();
    jwt = accessToken.getJwtToken();
  });

  return jwt;
};

export const getJwtIDToken = async () => {
  let jwt;
  await Auth.currentSession().then(res => {
    const identityToken = res.getIdToken();
    jwt = identityToken.getJwtToken();
  });

  return jwt;
};

export const logOff = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    log.error(error);
  }
};

// -------------------- GRAPHQL --------------------

// -------------- ADMIN
export const getAdmin = async () =>
  await API.graphql(graphqlOperation(queries.getAdmin));

export const newAdmin = async (username, _name, surname, email) =>
  await API.graphql(
    graphqlOperation(mutations.newAdmin, {
      username,
      _name,
      surname,
      email
    })
  );

export const deleteAdmin = async username =>
  await API.graphql(
    graphqlOperation(mutations.deleteAdminUser, {
      username
    })
  );

// -------------- CUSTOMER
export const getCustomers = async () =>
  await API.graphql(graphqlOperation(queries.getCustomers));

export const newCustomer = async (crm_id, ragione_sociale, c_description) =>
  await API.graphql(
    graphqlOperation(mutations.newCustomer, {
      crm_id,
      ragione_sociale,
      c_description
    })
  );

export const updateCustomer = async (crm_id, ragione_sociale, c_description) =>
  await API.graphql(
    graphqlOperation(mutations.updateCustomer, {
      crm_id,
      ragione_sociale,
      c_description
    })
  );

export const deleteCustomer = async crm_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteCustomer, {
      crm_id
    })
  );

// ---------- APP USER
export const updateAppUser = async (
  app_user_id,
  name,
  surname,
  email,
  phone,
  language,
  user_map_style
) =>
  await API.graphql(
    graphqlOperation(mutations.updateAppUser, {
      app_user_id,
      name,
      surname,
      email,
      phone,
      language,
      user_map_style
    })
  );

export const getAppUser = async (app_user_id) =>
  await API.graphql(
    graphqlOperation(queries.getAppUser, {
      app_user_id
    })
  );

// ---------------- TECH USER
export const getTechUsersByCustomer = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.getTechUsersByCustomer, { crm_id })
  );

export const newTechUser = async (username, email, policy_id, customer_id) =>
  await API.graphql(
    graphqlOperation(mutations.newTechUser, {
      username,
      email,
      policy_id,
      customer_id
    })
  );

export const updateTechUser = async (username, email, policy_id) =>
  await API.graphql(
    graphqlOperation(mutations.updateTechUser, {
      username,
      email,
      policy_id
    })
  );

export const deleteTechUser = async username =>
  await API.graphql(
    graphqlOperation(mutations.deleteTechUser, {
      username
    })
  );

export const changeTechUserState = async (username, state) =>
  await API.graphql(
    graphqlOperation(mutations.changeTechUserState, {
      username,
      state
    })
  );

export const getPoliciesTU = async () =>
  await API.graphql(graphqlOperation(queries.getPoliciesTU));

// --------------- POLICY
export const getPolicies = async () =>
  await API.graphql(graphqlOperation(queries.getPolicies));

export const newPolicy = async (policy_name, policy_description) =>
  await API.graphql(
    graphqlOperation(mutations.newPolicy, {
      policy_name,
      policy_description
    })
  );

export const updatePolicy = async (
  policy_id,
  policy_name,
  policy_description
) =>
  await API.graphql(
    graphqlOperation(mutations.updatePolicy, {
      policy_id,
      policy_name,
      policy_description
    })
  );

export const deletePolicy = async policy_id =>
  await API.graphql(
    graphqlOperation(mutations.deletePolicy, {
      policy_id
    })
  );

export const getAppUserPolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.listAppUserPolicy, {
      input
    })
  );

export const getEnabledServicesPolicy = async input =>
  await API.graphql(
    graphqlOperation(queries.listEnabledServicesPolicy, {
      input
    })
  );

// --------------- WORKSPACE
export const getWorkspacesByPolicy = async policy_id =>
  await API.graphql(
    graphqlOperation(queries.getWorkspacesByPolicy, { policy_id })
  );

export const newWorkspace = async (
  workspace_name,
  n_calls,
  start_time,
  stop_time,
  date_type,
  policy_id
) =>
  await API.graphql(
    graphqlOperation(mutations.newWorkspace, {
      workspace_name,
      n_calls,
      start_time,
      stop_time,
      date_type,
      policy_id
    })
  );

export const updateWorkspace = async (
  workspace_name,
  n_calls,
  start_time,
  stop_time,
  date_type,
  policy_id
) =>
  await API.graphql(
    graphqlOperation(mutations.updateWorkspace, {
      workspace_name,
      n_calls,
      start_time,
      stop_time,
      date_type,
      policy_id
    })
  );

export const deleteWorkspace = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(mutations.deleteWorkspace, {
      workspace_name,
      policy_id
    })
  );

export const getDomainsWS = async () =>
  await API.graphql(graphqlOperation(queries.getDomainsWS));

export const getDomainsByWS = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(queries.getDomainsByWS, { workspace_name, policy_id })
  );

// --------------- LAYER
export const getDBLayers = async (workspace_name, policy_id) =>
  await API.graphql(
    graphqlOperation(queries.getDBLayers, { workspace_name, policy_id })
  );

export const createLayer = async (workspace_name, policy_id, layer_name) =>
  await API.graphql(
    graphqlOperation(mutations.createLayer, {
      workspace_name,
      policy_id,
      layer_name
    })
  );

export const deleteLayer = async (workspace_name, policy_id, layer_name) =>
  await API.graphql(
    graphqlOperation(mutations.deleteLayer, {
      workspace_name,
      policy_id,
      layer_name
    })
  );

export const getGlobalLayer = async service_id =>
  await API.graphql(graphqlOperation(queries.getGlobalLayer, {
    service_id
  }));

// --------------- WS-DOMAIN
export const addDomain = async (workspace_name, policy_id, domain_id) =>
  await API.graphql(
    graphqlOperation(mutations.addDomain, {
      workspace_name,
      policy_id,
      domain_id
    })
  );

export const deleteDomainWS = async (workspace_name, policy_id, domain_id) =>
  await API.graphql(
    graphqlOperation(mutations.deleteDomainWS, {
      workspace_name,
      policy_id,
      domain_id
    })
  );

// --------------- DOMAIN
export const getDomains = async () =>
  await API.graphql(graphqlOperation(queries.getDomains));

export const newDomain = async (
  domain_id,
  domain_name,
  domain_description,
  polygon
) =>
  await API.graphql(
    graphqlOperation(mutations.newDomain, {
      domain_id,
      domain_name,
      domain_description,
      polygon
    })
  );

export const updateDomain = async (
  domain_id,
  domain_name,
  domain_description,
  polygon
) =>
  await API.graphql(
    graphqlOperation(mutations.updateDomain, {
      domain_id,
      domain_name,
      domain_description,
      polygon
    })
  );

export const deleteDomain = async domain_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteDomain, {
      domain_id
    })
  );

// ---------- CHECK

export const checkUserPolicy = async policy_id =>
  await API.graphql(graphqlOperation(queries.checkUserPolicy, { policy_id }));

export const checkUserDomain = async domain_id =>
  await API.graphql(graphqlOperation(queries.checkUserDomain, { domain_id }));

export const listTranslations = async lang =>
  await API.graphql(graphqlOperation(queries.listTranslations, { lang }));

// ---------- TICKETING SERVICE
export const getTicketingServiceConfiguration = async service_id =>
  await API.graphql(
    graphqlOperation(queries.getTicketingServiceConfiguration, {
      service_id
    })
  );

export const createTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.createTicketingRequest, { input })
  );

export const linkLocationToTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkLocationToTicketingRequest, { input })
  );

export const listEvasionLimitSlots = async dates_to_check =>
  await API.graphql(
    graphqlOperation(queries.listEvasionLimitSlots, { dates_to_check })
  );

export const listComuniProvince = async is_prov =>
  await API.graphql(
    graphqlOperation(queries.listComuniProvince, { is_prov })
  );

export const listDocumentMeteoTypes = async type =>
  await API.graphql(
    graphqlOperation(queries.listDocumentMeteoTypes, { type })
  );

export const getTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(queries.getTicketingRequest, { input })
  );

export const deleteTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.deleteTicketingRequest, { input })
  );

export const linkDocumentS3ToTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.linkDocumentS3ToTicketingRequest, { input })
  );

export const getLinkedDocumentS3toTicketingRequest = async request_id =>
  await API.graphql(
    graphqlOperation(queries.getLinkedDocumentS3toTicketingRequest, {
      request_id
    })
  );

export const deleteDocumentS3 = async document_s3_id =>
  await API.graphql(
    graphqlOperation(mutations.deleteDocumentS3, {
      document_s3_id
    })
  );

export const editTicketingRequest = async input =>
  await API.graphql(
    graphqlOperation(mutations.editTicketingRequest, { input })
  );

export const listTicketingRequestLimit = async crm_id =>
  await API.graphql(
    graphqlOperation(queries.listTicketingRequestLimit, { crm_id })
  );

// ---------- GLOBAL LAYERS
export const createGlobalLayer = async input =>
  await API.graphql(graphqlOperation(mutations.createGlobalLayer, { input }));

export const editGlobalLayer = async input =>
  await API.graphql(graphqlOperation(mutations.editGlobalLayer, { input }));

export const getCustomerLayer = async crm_id =>
  await API.graphql(graphqlOperation(queries.getCustomerLayer, { crm_id }));

export const deleteCustomerLayer = async input =>
  await API.graphql(graphqlOperation(mutations.deleteCustomerLayer, { input }));

// ---------- MANUALS
export const listServiceManuals = async app_user_id =>
  await API.graphql(graphqlOperation(queries.listServiceManuals, { app_user_id }));

export const listCustomerManuals = async customer_id =>
  await API.graphql(graphqlOperation(queries.listCustomerManuals, { customer_id }));

// ---------- DEFAULT SERVICE
export const getDefaultService = async input =>
  await API.graphql(graphqlOperation(queries.getDefaultService, { input }));

// ---------- DEFAULT SERVICE
export const listFolderObjects = async input =>
  await API.graphql(
    graphqlOperation(queries.listFolderObjects, { input })
  );
