<template>
  <v-row>
    <v-btn
      color="RMwhite"
      class="pa-0 ma-1"
      min-height="40"
      min-width="40"
      @click="zoomIn()"
    >
      <SvgIcon
        :title="'Zoom in'"
        :path="
          'M12,2 C11.2103929,2 10.5714286,2.63896429 10.5714286,3.42857143 L10.5714286,10.5714286 L3.42857143,10.5714286 C2.63896429,10.5714286 2,11.2103929 2,12 C2,12.7896071 2.63896429,13.4285714 3.42857143,13.4285714 L10.5714286,13.4285714 L10.5714286,20.5714286 C10.5714286,21.3610357 11.2103929,22 12,22 C12.7896071,22 13.4285714,21.3610357 13.4285714,20.5714286 L13.4285714,13.4285714 L20.5714286,13.4285714 C21.3610357,13.4285714 22,12.7896071 22,12 C22,11.2103929 21.3610357,10.5714286 20.5714286,10.5714286 L13.4285714,10.5714286 L13.4285714,3.42857143 C13.4285714,2.63896429 12.7896071,2 12,2 Z'
        "
      />
    </v-btn>
    <v-btn
      color="RMwhite"
      class="pa-0 ma-1"
      min-height="40"
      min-width="40"
      @click="zoomOut()"
    >
      <SvgIcon
        :rotate="'90'"
        :title="'Zoom out'"
        :path="
          'M12,2 C11.2103929,2 10.5714286,2.63896429 10.5714286,3.42857143 L10.5714286,10.5714286 L10.5714286,13.4285714 L10.5714286,20.5714286 C10.5714286,21.3610357 11.2103929,22 12,22 C12.7896071,22 13.4285714,21.3610357 13.4285714,20.5714286 L13.4285714,13.4285714 L13.4285714,10.5714286 L13.4285714,3.42857143 C13.4285714,2.63896429 12.7896071,2 12,2 Z'
        "
      />
    </v-btn>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import SvgIcon from '@/components/utils/SvgIcon.vue';

export default {
  components: {
    SvgIcon
  },
  methods: {
    ...mapActions(['zoomIn', 'zoomOut'])
  }
};
</script>
