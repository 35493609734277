<template>
  <div>
    <div
      id="map"
      class="map fill-height"
      :style="mapSize"
    />
    <marker-on-map />
    <popup-on-map />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    MarkerOnMap: () =>
      import('@/components/utils/MarkerOnMap'),
    PopupOnMap: () =>
      import('@/components/utils/PopupOnMap')
  },
  computed: {
    ...mapState(['map', 'app_user']),
    mapSize() {
      const height = this.$route.path.includes('reports') ? 'max-height: 500px' : 'min-height: 100vh';
      const marginFull = this.mobileBreakpoints() ? 0 : 85;
      const margin = this.$route.path.includes('reports') ? 25 : marginFull;

      return `${height}; margin-left: ${margin}px; width: calc(100% - ${margin}px);`;
    }
  },
  watch: {
    $route() {}
  },
  async mounted() {
    this.map.zoom = this.app_user.app_user_data[0].zoom || 0;
    this.map.mapCenter[0] = Number(this.app_user.app_user_data[0].latitude) || 0;
    this.map.mapCenter[1] = Number(this.app_user.app_user_data[0].longitude) || 0;
    this.setPositionUser(this.map.mapCenter);
    await this.initiateOLMapAction();
    this.getLocation();
  },
  methods: {
    ...mapActions(['initiateOLMapAction', 'setPositionUser', 'getLocation']),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  background-color: #aad3df;
  position: absolute;
  margin-right: 0px;
  padding: 0;
}
</style>
