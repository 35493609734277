import { getAppUserPolicy, getEnabledServicesPolicy, listFolderObjects } from '@/api/api.js';

export const state = {
  appUserPolicy: [],
  enabledServicesPolicy: {
    DOC: [],
    URL: [],
    WS: [],
    TICKET: {
      enabled_service: [],
      permissions: []
    },
    ALERT_EXP: [],
    REPORTS: [],
    REPORT_HUB: []
  },
  hierarchyItems: [],
  ticketingServicePermissions: [],
  folderObjects: [],
  pathTranslation: '',
  pathTranslationDescription: '',
  overlay: null
};

export const mutations = {
  GET_APP_USER_POLICY(state, policiesArray) {
    state.appUserPolicy = [];
    state.appUserPolicy = policiesArray;
  },
  GET_ENABLED_SERVICES_POLICY(state, data) {
    if (data.service_type !== 'WS' && data.policiesArray.WS.length === 0 && state.enabledServicesPolicy.WS.length > 0) {
      const completeData = data.policiesArray;
      completeData.WS = state.enabledServicesPolicy.WS;
      state.enabledServicesPolicy = completeData;
    } else {
      state.enabledServicesPolicy = data.policiesArray;
    }
  },
  GET_FOLDER_OBJECTS(state, data) {
    state.folderObjects = [];
    state.folderObjects = data;
  }
};

export const actions = {
  async storeAppUserPolicy({ dispatch, commit }, data) {
    try {
      const listPolicy = await getAppUserPolicy(data);
      commit('GET_APP_USER_POLICY', listPolicy.data.listAppUserPolicy);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAppUserPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeEnabledServicesPolicy({ dispatch, commit }, data) {
    try {
      const listPolicy = await getEnabledServicesPolicy(data);
      commit(
        'GET_ENABLED_SERVICES_POLICY',
        { policiesArray: listPolicy.data.listEnabledServicesPolicy,
          service_type: data.service_type }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeEnabledServicesPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeFolderObjects({ dispatch, commit }, data) {
    try {
      const resp = await listFolderObjects(data);
      commit('GET_FOLDER_OBJECTS', resp.data.listFolderObjects.list);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeFolderObjects',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
