import router from '@/router';

export const state = {
  drawerVisibility: false,
  workspaceSidebar: false,
  menuSideBar: false,
  disableWorkspaceSidebar: false,
  disableMenuSideBar: false,
  drawerTitle: '',
  searchDialog: false,
  globalLayersDialog: false
};

export const mutations = {};

export const actions = {
  resetDrawer({ state }) {
    state.drawerVisibility = false;
    state.drawerTitle = '';
    router.push('/');
  }
};
