export const getAdmin = /* GraphQL */ `
  query getAdmin {
    listAdmin {
      username
      _name
      surname
      email
    }
  }
`;

export const getCustomers = /* GraphQL */ `
  query getCustomers {
    listCustomers {
      crm_id
      ragione_sociale
      c_description
    }
  }
`;

export const getTechUsersByCustomer = /* GraphQL */ `
  query getTechUsersByCustomer($crm_id: String!) {
    listTechUsersByCustomer(crm_id: $crm_id) {
      username
      email
      _state
      policy_id
    }
  }
`;

export const getPoliciesTU = /* GraphQL */ `
  query getPolicies {
    listPolicies {
      policy_id
      policy_name
    }
  }
`;

export const getAppUser = /* GraphQL */ `
  query getAppUser($app_user_id: String!) {
    getAppUser(app_user_id: $app_user_id) {
    _name
    _state
    app_user_id
    email
    language
    phone
    surname
    username
    c_description
    crm_id
    customer_id
    latitude
    logo
    longitude
    state
    ragione_sociale
    zoom
    map_style
    user_map_style
    }
  }
`;

export const getPolicies = /* GraphQL */ `
  query getPolicies {
    listPolicies {
      policy_id
      policy_name
      policy_description
    }
  }
`;

export const getWorkspacesByPolicy = /* GraphQL */ `
  query getWorkspacesByPolicy($policy_id: Int!) {
    listWorkspacesByPolicy(policy_id: $policy_id) {
      workspace_name
      n_calls
      start_time
      stop_time
      date_type
    }
  }
`;

export const getDBLayers = /* GraphQL */ `
  query getDBLayers($workspace_name: String!, $policy_id: Int!) {
    listDBLayers(workspace_name: $workspace_name, policy_id: $policy_id) {
      workspace_name
      policy_id
      layer_name
    }
  }
`;

export const getDomainsWS = /* GraphQL */ `
  query getDomainsWS {
    listDomains {
      domain_id
      domain_name
    }
  }
`;

export const getDomainsByWS = /* GraphQL */ `
  query getDomainsByWS($workspace_name: String!, $policy_id: Int!) {
    listDomainsByWorkspace(
      workspace_name: $workspace_name
      policy_id: $policy_id
    ) {
      domain_id
      domain_name
    }
  }
`;

export const getDomains = /* GraphQL */ `
  query getDomains {
    listDomains {
      domain_id
      domain_name
      domain_description
      polygon
    }
  }
`;

export const checkUserDomain = /* GraphQL */ `
  query checkUserDomain($domain_id: Int!) {
    checkUserDomain(domain_id: $domain_id) {
      result
    }
  }
`;

export const checkUserPolicy = /* GraphQL */ `
  query checkUserPolicy($policy_id: Int!) {
    checkUserPolicy(policy_id: $policy_id) {
      result
    }
  }
`;

export const listTranslations = /* GraphQL */ `
  query listTranslations($lang: String!) {
    listTranslations(lang: $lang) {
      label_code
      label_value
      lang
    }
  }
`;

export const listAppUserPolicy = /* GraphQL */ `
  query listAppUserPolicy($input: listAppUserPolicyInput!) {
    listAppUserPolicy(input: $input) {
      REPORT_HUB {
        father_id
        node_description
        node_id
        node_name
        path_s3_bucket
        service_id
        sort_id
        crm_folder
      }
      DOC {
        father_id
        node_description
        node_id
        node_name
        path_s3_bucket
        service_id
        sort_id
      }
      URL {
        father_id
        external_link
        node_description
        node_id
        node_name
        service_id
        sort_id
      }
      WS {
        service_id
        service_name
        description
        service_type
        manual_s3_path
        service_state
        ws_id
        ws_name
        ws_group
        animation_limit_date_type
        animation_limit_future
        animation_limit_past
        animation_step_date_type
        archive_start_date
        animation_step
        refresh_interval_step_type
        refresh_interval_step
        meteograms {
          meteogram_id
          location_name
          latitude
          longitude
        }
        ws_policy {
          pws_id
          policy_id
          service_id
          is_graph
          is_animation
          is_table
          is_meteogram
          is_map
          start_date
          end_date
          date_type
          layer_name
          ws_id
          layer_type
          layer_color
          layer_label
          udm
          domain_id
          layer_style
        }
        globalLayers {
          layer_name
          global_layer_id
          layer_description
          layer_polygon
          color
          width
          layer_type
        }
      }
      TICKET {
        service_id
        ticket_id
        date_limit
        config {
          document_id
          document_type 
          meteo_types {
            meteo_id
            name
            is_auto
            is_manual
          }
        }   
      }
    }
  }
`;

export const listEnabledServicesPolicy = /* GraphQL */ `
  query listEnabledServicesPolicy($input: listEnabledServicesPolicyInput!) {
    listEnabledServicesPolicy(input: $input) {
        DOC {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_id
          service_name
          service_state
          service_type
        }
        URL {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
        }
        WS {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
        }
        TICKET {
          enabled_service {
          app_user_id
          app_user_ticket_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
          }
          permissions {
            service_id
            node_id
            father_id
            node_name
            node_description
            sort_id
            permission_id
            permission_type
            permission_name
            surname
            email
            phone
            crm_id
            ragione_sociale
            _name
          }
        }
        ALERT_EXP {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
        }
        REPORTS {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_name
          service_state
          service_type
          service_id
        }
        REPORT_HUB {
          app_user_id
          manual_s3_path
          description
          policy_id
          service_id
          service_name
          service_state
          service_type
          crm_folder
        }
      }
  }
`;

export const listEvasionLimitSlots = /* GraphQL */ `
  query listEvasionLimitSlots($dates_to_check: [String]!) {
    listEvasionLimitSlots(dates_to_check: $dates_to_check) {
      evasion_limit
      max_slots
      total_slots
      is_prov
      can_be_moved
    }
  }
`;

export const listComuniProvince = /* GraphQL */ `
  query listComuniProvince($is_prov: Boolean!) {
    listComuniProvince(is_prov: $is_prov) {
      list
    }
  }
`;

export const listDocumentMeteoTypes = /* GraphQL */ `
  query listDocumentMeteoTypes($type: String!) {
    listDocumentMeteoTypes(type: $type) {
      list
    }
  }
`;

export const getTicketingServiceConfiguration = /* GraphQL */ `
  query getTicketingServiceConfiguration($service_id: String!) {
    getTicketingServiceConfiguration(service_id: $service_id) {
      service_id
      ticket_id
      date_limit
      config {
        document_id
        document_type 
        meteo_types {
          meteo_id
          name
	        is_auto
	        is_manual
        }
      }
    }
  }
`;

export const getTicketingRequest = /* GraphQL */ `
  query getTicketingRequest($input: [GetTicketingRequestInput]!) {
    getTicketingRequest(input: $input) {
      service_id
      request_id
      request_type
      app_user_id
      app_user_ticket_id
      document_type
      evasion_date
      evasion_limit
      request_date
      request_state
      notes
      additional_notes
      locations {
        request_id
        location_id
        latitude
        longitude
        meteo_type
        address
        start_date
        end_date
      }
      node_id
      father_id
      node_name
      node_description
      sort_id
      policy_id
      username
      request_number
      request_count
      full_address
      surname
      email
      phone
      crm_id
      ragione_sociale
      _name
      full_name
    }
  }
`;

export const getLinkedDocumentS3toTicketingRequest = /* GraphQL */ `
  query getLinkedDocumentS3toTicketingRequest($request_id: String!) {
    getLinkedDocumentS3toTicketingRequest(request_id: $request_id) {
      app_user_ticket_id
      request_id
      request_type
      document_s3_id
      file_name
      document_creator
      document_s3_path
    }
  }
`;

export const listTicketingRequestLimit = /* GraphQL */ `
  query listTicketingRequestLimit($crm_id: [String]!) {
    listTicketingRequestLimit(crm_id: $crm_id) {
      crm_id
      request_limit
      request_count
      limit_reached
    }
  }
`;

export const getGlobalLayer = /* GraphQL */ `
  query getGlobalLayer($service_id: [String]) {
    getGlobalLayer(service_id: $service_id) {
      global_layer_id
      layer_name
      layer_description
      layer_polygon
      color
      width
      layer_type
    }
  }
`;

export const getCustomerLayer = /* GraphQL */ `
  query getCustomerLayer($crm_id: String!) {
    getCustomerLayer(crm_id: $crm_id) {
      crm_id
      global_layer_id
      layer_name
      layer_description
      layer_polygon
      color
      width
      layer_type
    }
  }
`;

export const listServiceManuals = /* GraphQL */ `
  query listServiceManuals($app_user_id: String!) {
    listServiceManuals(app_user_id: $app_user_id) {
      service_id
      service_name
      service_type
      service_ws_group
      manual_s3_path
    }
  }
`;

export const listCustomerManuals = /* GraphQL */ `
  query listCustomerManuals($customer_id: String!) {
    listCustomerManuals(customer_id: $customer_id) {
      manual_s3_path
      label_code
      label_value
      customer_id
    }
  }
`;

export const listFolderObjects = /* GraphQL */ `
  query listFolderObjects($input: ListFolderObjects!) {
    listFolderObjects(input: $input) {
      list
    }
  }
`;

export const getDefaultService = /* GraphQL */ `
  query getDefaultService($input: GetDefaultServiceInput!) {
    getDefaultService(input: $input) {
      GLOBAL {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
        timeStamp_token
      }
      Archive {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
        timeStamp_token
      }
      RealTime {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
        timeStamp_token
      }
      Forecast {
        default_id
        service_type
        app_scope
        service_path
        drawer_title
        global_layer_id {
          id
          name
        }
        customer_layer_id {
          id
          name
        }
        service_details {
          service_id
          layer_name
          layer_type
          is_meteogram
          service_name
        }
        timeStamp_token
      }
    }
  }
`;
