<template>
  <v-flex
    offset-2
    offset-lg-1
    class="flex-margin"
  >
    <div
      v-if="mobileBreakpoints()"
      class="d-flex mb-2"
      style="justify-content: end;"
    >
      <v-icon
        class="text-right"
        color="red"
        size="28"
        @click="$router.push('/')"
      >
        mdi-close
      </v-icon>
    </div>
    <v-card v-if="!isHTML">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title
        class="card-title v-card-scroll"
        color="black"
      >
        {{ $t('Reports.name') }}
      </v-card-title>

      <v-tabs
        v-model="selectedTab"
        color="green"
        active-class="white"
        background-color="#f4f3f3"
        grow
      >
        <v-tab
          v-for="(type, index) in reportType"
          :key="index"
        >
          {{ type.label }}
        </v-tab>
      </v-tabs>

      <v-row
        v-if="selectedTab > 0"
        class="ms-1 mt-3 me-5"
      >
        <v-col cols="4">
          <v-select
            v-model="selectedYear"
            :items="yearsArray"
            label="Year"
          />
        </v-col>
        <v-col cols="4">
          <v-menu
            ref="filterReportsMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="rangeDateText"
                multiple
                chips
                small-chips
                label="Filter range date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                class="shrink"
                clearable
                @click:clear="closeDateRange()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              :picker-date.sync="pickerDate"
              :allowed-dates="filterAllowedDates"
              no-title
              range
              scrollable
              :min="rangeDate[0]"
              :max="moment().format('YYYY-MM-DD')"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="changeFilterDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            class="shrink"
            clearable
            prepend-icon="mdi-magnify"
            :label="$t('AlertExPost.search')"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(type, index) in reportType"
          :key="index"
        >
          <v-card>
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              :search="search"
              :custom-filter="customSearch"
              sort-by="reportDate"
              :sort-desc="true"
              class="elevation-1"
              :footer-props="{
                itemsPerPageAllText: $t('Table.all'),
                itemsPerPageText: $t('Table.rowsPerPage'),
                pageText: `{0}-{1} ${$t('Table.of')} {2}`
              }"
              :no-data-text="$t('Table.noData')"
            >
              <template v-slot:[`item.reportType`]="{item}">
                {{ $t(`Reports.${item.reportType}`).toUpperCase() }}
              </template>
              <template v-slot:[`item.reportDate`]="{item}">
                {{ moment(item.reportDate).format('DD/MM/YYYY HH:mm') }}
              </template>
              <!-- <template v-slot:top>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('AlertExPost.search')"
                  class="mx-5 mb-5 mt-0 pt-5"
                  single-line
                  hide-details
                />
              </template> -->
              <template v-slot:[`item.view`]="{ item }">
                <v-btn
                  small
                  color="success mr-2"
                  dark
                  @click="viewReport(item)"
                >
                  HTML
                </v-btn>

                <v-btn
                  class="me-3"
                  small
                  outlined
                  color="success"
                  dark
                  @click="downloadReport(item)"
                >
                  PDF
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card
      v-else
      class="mb-8"
    >
      <v-card-title
        class="card-title v-card-scroll d-flex justify-space-between"
        color="black"
      >
        Bollettino del {{ moment(report.reportDate).format('DD/MM/YYYY HH:mm') }}
        <v-btn
          outlined
          light
          color="success"
          @click="isHTML=false"
        >
          <SvgIcon
            class="me-5"
            :color1="'#FFFFFF'"
            :color2="'#0da344'"
            :path="
              'M1.25,5 C1.94035594,5 2.5,5.55964406 2.5,6.25 L2.5,18.75 C2.5,19.4403559 1.94035594,20 1.25,20 C0.559644063,20 -3.10862447e-15,19.4403559 -3.10862447e-15,18.75 L-8.8817842e-16,6.25 C-4.4408921e-16,5.55964406 0.559644063,5 1.25,5 Z M13.4024897,6.23488117 C13.6240457,6.45035652 13.7490822,6.74303525 13.75,7.04741285 C13.75,7.35266385 13.626811,7.64536556 13.4061846,7.86173729 L9.884,11.3 L22.5,11.3 C23.1903559,11.3 23.75,11.8596441 23.75,12.55 C23.75,13.2403559 23.1903559,13.8 22.5,13.8 L9.852,13.8 L13.4061846,17.2709424 C13.8658368,17.7216364 13.8640011,18.4497707 13.4025073,18.8977756 C12.9419373,19.3466768 12.1963645,19.3448841 11.7367123,18.8941843 L6.08843393,13.3780154 C5.86687786,13.1580513 5.74553636,12.8608746 5.75012559,12.5520151 L5.75010794,12.5519116 C5.75286594,12.2538317 5.87421473,11.9674276 6.08841628,11.7546414 L11.7366947,6.23847242 C12.1963469,5.78867481 12.9419196,5.78688206 13.4024897,6.23488117 Z'
            "
          />
          {{ $t('Reports.list') }}
        </v-btn>
      </v-card-title>
      <reports-detail
        :report="report"
        :geojson="geojsonResp.filter(e => e !== undefined)"
        :template-headers="templateHeaders"
        :location-headers="locationHeaders"
      />
    </v-card>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getReportOrganization } from '@/api/services.js';
import SvgIcon from '@/components/utils/SvgIcon.vue';
import moment from 'moment-business-days';

export default {
  components: {
    SvgIcon,
    ReportsDetail: () => import('@/components/utils/ReportsDetail')
  },
  data: () => ({
    search: null,
    pickerDate: null,
    areaID: '',
    rangeMenu: false,
    isHTML: false,
    overlay: false,
    report: {},
    selectedTab: {},
    rangeDate: [],
    geojsonResp: [],
    templateHeaders: [],
    itemsArray: [],
    latestReportsArray: [],
    forecastingArray: [],
    nowcastingArray: [],
    trendArray: [],
    reportType: [],
    headers: [],
    locationHeaders: [],
    yearsArray: [],
    allowedDates: [],
    selectedYear: ''
  }),

  computed: {
    ...mapState(['app_user', 'map', 'other_services']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? moment(this.rangeDate[0]).format('DD/MM/YYYY') : '';
      const end_date = this.rangeDate[1] ? moment(this.rangeDate[1]).format('DD/MM/YYYY') : '';

      return this.rangeDate.length ? `${start_date} - ${end_date}` : '';
    },
    filteredItems() {
      return this.rangeDate.length < 2
        ? this.itemsArray
        : this.itemsArray.filter(e => {
          const start = moment(this.rangeDate[0]).format('YYYY-MM-DD');
          const end = moment(this.rangeDate[1]).format('YYYY-MM-DD');

          return moment(e.reportDate, 'YYYY-MM-DD').isSameOrAfter(start, 'day') && moment(e.reportDate, 'YYYY-MM-DD').isSameOrBefore(end, 'day');
        });
    }
  },

  watch: {
    selectedTab(newTab) {
      this.isHTML = false;
      const selectedType = this.reportType[newTab].type;
      this.itemsArray = this.getItemsArray(selectedType);
      this.removeEveryLayer();
    },
    async selectedYear(newYear) {
      if (newYear) {
        this.overlay = true;
        this.search = null;
        this.rangeDate = [];
        this.pickerDate = `${newYear}-01`;
        this.allowedDates = this.fetchAllowedDates();
        await this.fetchReports(newYear);
        this.itemsArray = this.getItemsArray(this.reportType[this.selectedTab].type);
        this.overlay = false;
      }
    }
  },

  async created () {
    this.overlay = true;
    this.other_services.crmName = this.app_user.app_user_data[0].ragione_sociale;
    // this.other_services.crmName = "TEST - Reply";
    // this.other_services.crmName = "Radarmeteo";
    this.reportType = [
      { type: 'LatestReports', label: this.$t('Reports.latestReports') },
      { type: 'Forecasting', label: this.$t('Reports.forecasting') },
      { type: 'Nowcasting', label: this.$t('Reports.nowcasting') },
      { type: 'Trend', label: this.$t('Reports.trend') }
    ];
    this.headers = [
      { text: this.$t('Reports.type'), value: 'reportType', sortable: true },
      { text: this.$t('Reports.num'), value: 'number', sortable: true },
      { text: this.$t('Reports.date'), value: 'reportDate', sortable: true },
      { text: this.$t('Reports.view'), value: 'view', align: 'end', sortable: false }
    ];

    const organizationsResp = await getReportOrganization();
    this.other_services.organization = organizationsResp.data.items.find(item => item.name === this.other_services.crmName);

    for await (const type of this.reportType) {
      if (type.type !== 'LatestReports') {
        const latestReportResp = await this.getLatestReportApi(type.type);

        if (latestReportResp.data.items.length > 0) {
          this.latestReportsArray.push(latestReportResp.data.items[0]);
        }
      }
    }

    const oldestYearResp = await this.getOldestReportApi();
    this.yearsArray = this.generateYearsArray(moment(oldestYearResp.data.items[0].reportDate).format('YYYY'));
    this.selectedYear = this.yearsArray[0];
    this.pickerDate = `${this.selectedYear}-01`;
    this.allowedDates = this.fetchAllowedDates();
    this.overlay = false;
  },

  methods: {
    ...mapActions(['getReportsApi', 'getLatestReportApi', 'getOldestReportApi', 'getReportTemplateApi', 'getReportGEOJSONApi', 'loadReportGEOJSON', 'downloadReport', 'removeEveryLayer', 'fitGEOJSONextent', 'getSingleReportApi']),
    customSearch(value, item) {
      const translatedValue = this.$t(`Reports.${value}`).toLowerCase();

      return String(translatedValue).toLowerCase().includes(String(item).toLowerCase());
    },
    getItemsArray(type) {
      switch (type) {
        case 'Forecasting':
          return this.forecastingArray;
        case 'Nowcasting':
          return this.nowcastingArray;
        case 'Trend':
          return this.trendArray;
        default:
          return this.latestReportsArray;
      }
    },
    async viewReport(item) {
      this.overlay = true;
      let templateResp = '';
      let singleReportResp = '';

      if (item) {
        templateResp = await this.getReportTemplateApi(item.reportTemplateId);
        singleReportResp = await this.getSingleReportApi(item.id);
        // } else {
        //   const latestReportResp = this.enabledType.type === 'LatestReports'
        //     ? await this.getLatestReportApi(this.capitalizeFirstLetter(this.latestReportsArray[0].reportType))
        //     : await this.getLatestReportApi(this.enabledType.type);

      //   templateResp = await this.getReportTemplateApi(latestReportResp.data.items[0].reportTemplateId);
      //   singleReportResp = await this.getSingleReportApi(latestReportResp.data.items[0].id);
      }

      this.report = singleReportResp.data;

      this.geojsonResp = [];
      this.templateHeaders = [];
      this.locationHeaders = [];

      for await (const section of templateResp.data.sections) {
        this.locationHeaders.push(section.name);
        const headers = ['Fascia'];

        for await (const area of section.subSections) {
          this.geojsonResp.push(await this.getReportGEOJSONApi([singleReportResp.data.id, area.id]));
          headers.push(area.name);
        }

        this.templateHeaders.push(headers);
      }

      this.isHTML = false;
      this.$nextTick(() => {
        this.isHTML = true;
      });

      await this.removeEveryLayer();

      if (this.map.OLmap.getLayers().getArray().slice(1).length > 0) {
        const promises = [];
        this.geojsonResp.forEach(item => {
          promises.push(this.loadReportGEOJSON(item.data));
        });
        await Promise.all(promises);
        this.fitGEOJSONextent();
      }

      this.overlay = false;
    },
    changeFilterDate() {
      this.$refs.filterReportsMenu.save(this.rangeDate);
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.rangeDate = [];
      this.$refs.filterReportsMenu.save([]);
    },
    async fetchReports(year) {
      const reportTypeToArrayMap = {
        Forecasting: 'forecastingArray',
        Nowcasting: 'nowcastingArray',
        Trend: 'trendArray'
      };

      await Promise.all(
        this.reportType
          .filter(type => type.type !== 'LatestReports')
          .map(async (type) => {
            const items = [];
            let totalCount = 0;
            let page = 0;

            do {
              page += 1;
              const resp = await this.getReportsApi([type.type, year, page]);
              items.push(...resp.data.items);
              totalCount = resp.data.paging.totalCount;
            } while (items.length < totalCount);

            if (reportTypeToArrayMap[type.type]) {
              this[reportTypeToArrayMap[type.type]] = items;
            }
          })
      );
    },
    fetchAllowedDates() {
      const datesArray = [];
      const startOfYear = moment(this.selectedYear, 'YYYY').startOf('year');
      const endOfYear = moment(this.selectedYear, 'YYYY').endOf('year');

      const currentDate = startOfYear.clone();

      while (currentDate.isSameOrBefore(endOfYear)) {
        datesArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
      }

      return datesArray;
    },
    filterAllowedDates(date) {
      return this.allowedDates.includes(date);
    },
    generateYearsArray(startYear) {
      const currentYear = moment().format('YYYY');

      return Array.from({ length: parseInt(currentYear) - parseInt(startYear) + 1 }, (_, i) => parseInt(startYear) + i).reverse();
    },
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.flex-margin{
  margin-right: 2%;
  margin-top: 2%
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.card {
  margin-right:5%;
}

@media (max-device-width: 599px) {
  .flex-margin {
    margin-bottom: 2%;
  }
}

@media (min-device-width: 600px) {
  .flex-margin {
    margin-left: calc(100px + 2%);
  }
}

</style>
