<template>
  <div>
    <div
      class="elevation-0 mr-4 d-flex top-component-class"
      style="pointer-events: none"
    >
      <v-row class="mr-16 ml-6 mt-0 mb-0 pb-2 fill-height d-flex justify-space-between">
        <div
          class="d-flex flex-column"
          style="justify-content: end;"
        >
          <legends-button v-if="!mobileBreakpoints()" />
          <v-divider
            v-else
            style="visibility:hidden"
          />

          <div
            v-if="map.mouseCoord && !mobileBreakpoints()"
            class="mouse-coord"
          >
            Lat: {{ map.mouseCoord[1].toFixed(4) }} <br>
            Lon: {{ map.mouseCoord[0].toFixed(4) }}
          </div>
        </div>
        <!-- <v-col>
          <span class="white--text">
            <strong>RadarCast</strong> - Testo da inserire ...
          </span>
        </v-col> -->
        <img
          v-if="(file_transfer.logoUrl != '')"
          id="customer-logo"
          class="mr-12"
          :src="file_transfer.logoUrl"
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    LegendsButton: () => import('@/components/layout/LegendsButton.vue')
  },
  computed: {
    ...
    mapState(['map', 'file_transfer'])
  },
  methods: {
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
#customer-logo {
  object-fit: contain;
  object-position: right bottom;
  width: 180px;
}
.top-component-class {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  margin-left: 5em;
  /* box-shadow: inset 0 -50vh 8vh -45vh rgba(0,0,0,0.8) !important; */
  /* top: 87vh; */
  /* left: 6.5em; */
}
@media (max-device-width:599px){
  .top-component-class {
    width: 100%;
    padding: 8px;
    margin:0px!important;
  }

  .top-component-class > div {
    margin:0px!important;
    align-items: center;
    padding: 0px!important;
  }

  #customer-logo{
    position: fixed;
    top: 0;
    right: 0;
    width: 130px !important;
    margin: 12px 12px 0px 0px!important;
    padding: 0px!important;
  }
}
.white-bg {
  background-color: rgb(255, 255, 255, .85);
  min-height: 100%;
}
.transparent-bg {
  background-color: transparent;
  scrollbar-width: thin;
}
.mouse-coord{
  margin-top: 8px;
  max-height: fit-content;
  max-width: fit-content;
  border-radius: 4px;
  background-color: rgba(255,255,255, 0.8);
  padding: 4px 8px;
}
/* .top-component-class-variant {
  position: absolute;
  min-height: 8em;
  bottom: 0;
  z-index: 4;
  margin-left: 17.5em;
  box-shadow: inset 0 -50vh 9vh -44vh #000000 !important;
} */
</style>
