<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-flex
      offset-2
      offset-lg1
      offset-xl1
      class="flex-margin"
    >
      <div
        v-if="mobileBreakpoints()"
        class="d-flex mb-2"
        style="justify-content: end;"
      >
        <v-icon
          class="text-right"
          color="red"
          size="28"
          @click="$router.push('/')"
        >
          mdi-close
        </v-icon>
      </div>
      <v-card class="mb-5">
        <v-card-title
          class="ticketing-card-title v-card-scroll d-flex justify-space-between"
          color="black"
        >
          {{ $t('TicketingTable.title') }}
          <div>
            <v-btn
              v-if="maxNum > 0"
              outlined
              :color="countColor"
              class="disable-events"
            >
              Ticket N. {{ currentNum }}/<b>{{ maxNum }}</b>
            </v-btn>
            <v-btn
              v-if="isResult"
              outlined
              light
              icon
              color="green"
              class="ms-5"
              @click="refreshSearch()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <ticketing-search-request-list
            ref="filtersRef"
            @is-loading="isLoading($event)"
            @show-results="showResults($event)"
          />
        </v-card-text>
      </v-card>
      <v-card v-if="isResult">
        <v-card-text>
          <v-alert
            text
            prominent
            outlined
            type="success"
            icon="mdi-cloud-alert"
          >
            {{ $t('TicketingTable.info') }}<br><br>
            {{ $t('TicketingTable.legend') }}
          </v-alert>
          <v-data-table
            id="requests-table"
            :mobile-breakpoint="0"
            :headers="headersTicketingRequestTable"
            :items="ticketing.ticketingRequestListFormatted || []"
            :items-per-page="15"
            width="800"
            show-expand
            :sort-by="['request_number', 'request_date']"
            :sort-desc="true"
            item-key="request_id"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 80],
              itemsPerPageAllText: $t('Table.all'),
              itemsPerPageText: $t('Table.rowsPerPage'),
              pageText: `{0}-{1} ${$t('Table.of')} {2}`
            }"
            :no-data-text="$t('Table.noFile')"
          >
            <template
              v-for="h in headersTicketingRequestTable"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`body.prepend`]>
              <tr>
                <td />
                <td>
                  <v-text-field
                    v-model="request_count"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="request_type"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="request_date"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="evasion_limit"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="evasion_date"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="node_name"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="customer"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="username"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="document_type"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="meteo_type"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="full_address"
                    type="text"
                  />
                </td>
                <td>
                  <v-text-field
                    v-model="request_state"
                    type="text"
                  />
                </td>
                <td />
              </tr>
            </template>
            <template v-slot:[`item.priority`]="{ item }">
              <change-request-state :state="item.priority" />
            </template>
            <template v-slot:[`item.request_state`]="{ item }">
              <change-request-state :state="item.request_state" />
            </template>
            <template v-slot:[`item.document_type`]="{ item }">
              <split-document-or-meteo-type :type="item.document_type" />
            </template>
            <template
              v-slot:[`item.meteo_type`]="{ item }"
            >
              <p
                v-for="(location, i) in item.locations"
                :key="i"
                class="address"
              >
                <split-document-or-meteo-type :type="location.meteo_type" />
              </p>
            </template>
            <template
              v-slot:[`item.full_address`]="{ item }"
            >
              <p
                v-for="(place, i) in item.locations"
                :key="i"
                class="address"
              >
                {{ place.address }} [{{ place.latitude }};{{ place.longitude }}]
              </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                :size="20"
                @click="requestDetail(item)"
              >
                mdi-card-search
              </v-icon>
              <delete
                @deleted="deleteRequest(item)"
              />
            </template>
            <template
              v-slot:[`expanded-item`]="{ item }"
            >
              <td
                :colspan="headersTicketingRequestTable.length +1"
                style="padding:0"
              >
                <v-data-table
                  id="locations-table"
                  :mobile-breakpoint="0"
                  :headers="headersTicketingLocationsTable"
                  :items="item.locations || []"
                  :items-per-page="15"
                  width="800"
                  hide-default-footer
                  disable-pagination
                  item-key="location_id"
                  :footer-props="{
                    itemsPerPageAllText: $t('Table.all'),
                    itemsPerPageText: $t('Table.rowsPerPage'),
                    pageText: `{0}-{1} ${$t('Table.of')} {2}`
                  }"
                  :no-data-text="$t('Table.noFile')"
                >
                  <template
                    v-for="h in headersTicketingLocationsTable"
                    v-slot:[`header.${h.value}`]="{ header }"
                  >
                    <span
                      :key="h.value"
                    >{{ $t(header.text) }}</span>
                  </template>
                  <template v-slot:[`item.meteo_type`]="{ item }">
                    <split-document-or-meteo-type :type="item.meteo_type" />
                  </template>
                  <template
                    v-slot:[`item.address`]="{ item }"
                  >
                    <span>
                      {{ `${item.address} [${item.latitude};${item.longitude}]` }}
                    </span>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
          <ticketing-detail-popup
            v-if="ticketing.viewTicketingDetailPopup"
            :request="request"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-business-days';

export default {
  components: {
    TicketingDetailPopup: () => import('@/components/layout/TicketingDetailPopup'),
    SplitDocumentOrMeteoType: () => import('@/components/utils/SplitDocumentOrMeteoType'),
    ChangeRequestState: () => import('@/components/utils/ChangeRequestState'),
    TicketingSearchRequestList: () => import('@/components/utils/TicketingSearchRequestList'),
    delete: () => import('@/components/utils/Delete')
  },
  data () {
    return {
      currentNum: 0,
      maxNum: 0,
      rangeMenu: false,
      isResult: false,
      overlay: false,
      canDelete: false,
      request_type: '',
      request_date: '',
      request_count: '',
      evasion_limit: '',
      evasion_date: '',
      node_name: '',
      customer: '',
      username: '',
      document_type: '',
      full_address: '',
      meteo_type: '',
      address: '',
      latitude: '',
      longitude: '',
      request_state: '',
      request: {},
      rangeDate: [],
      requestTable: [],
      locationTable: []
    };
  },
  computed: {
    ...mapState(['ticketing', 'policy', 'app_user']),
    rangeDateText() {
      const start_date = this.rangeDate[0] ? `${moment(this.rangeDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.rangeDate[1] ? `${moment(this.rangeDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    countColor() {
      const diff = this.maxNum - this.currentNum;

      if (diff <= 5) {
        return diff <= 0 ? 'red' : 'orange';
      }

      return 'green';
    },
    headersTicketingRequestTable() {
      const headersToReturn = [
        {
          text: 'TicketingTable.requestNumber',
          value: 'request_count',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_count.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.requestType',
          value: 'request_type',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.requestDate',
          value: 'request_date',
          filter: f => {
            return (f + '').toLowerCase().includes(this.request_date.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.evasionLimit',
          value: 'evasion_limit',
          filter: f => {
            return (f + '').toLowerCase().includes(this.evasion_limit.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.evasionDate',
          value: 'evasion_date',
          filter: f => {
            return (f + '').toLowerCase().includes(this.evasion_date.toLowerCase());
          },
          sortable: false,
          width: 110
        },
        {
          text: 'TicketingTable.node',
          value: 'node_name',
          filter: f => {
            return (f + '').toLowerCase().includes(this.node_name.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.customer',
          value: 'ragione_sociale',
          filter: f => {
            return (f + '').toLowerCase().includes(this.customer.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.requestUser',
          value: 'username',
          filter: f => {
            return (f + '').toLowerCase().includes(this.username.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Ticketing.fullName',
          value: 'full_name',
          align: ' d-none',
          sortable: false
        },
        {
          text: 'Ticketing.documentType',
          value: 'document_type',
          filter: f => {
            const textSplitted = f.split(', ');
            const textTranslated = this.$t(textSplitted[1]);

            return (textTranslated + '').toLowerCase().includes(this.document_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Ticketing.meteoType',
          value: 'meteo_type',
          filter: f => {
            const textSplitted = f.split(', ');
            const textTranslated = this.$t(textSplitted[1]);

            return (textTranslated + '').toLowerCase().includes(this.meteo_type.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'Ticketing.geo',
          value: 'full_address',
          filter: f => {
            return (f + '').toLowerCase().includes(this.full_address.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.requestState',
          value: 'request_state',
          filter: f => {
            switch (f) {
              case 'SENT':
                f = 'Inviata';
                break;
              case 'DELETED':
                f = 'Annullata';
                break;
              case 'TAKING CHARGE':
                f = 'Presa in carico';
                break;
              default:
                f = 'Evasa';
            }

            return (f + '').toLowerCase().includes(this.request_state.toLowerCase());
          },
          sortable: false
        },
        {
          text: 'TicketingTable.actions',
          value: 'actions',
          width: 100
        }
      ];

      return headersToReturn;
    },
    headersTicketingLocationsTable() {
      const headersToReturn = [
        {
          text: '',
          value: '',
          sortable: false
        },
        {
          text: 'ServiceCard.startDate',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'ServiceCard.endDate',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'Ticketing.meteoType',
          value: 'meteo_type',
          sortable: false
        },
        {
          text: 'Ticketing.address',
          value: 'address',
          sortable: false
        },
        {
          text: 'Ticketing.latitude',
          value: 'latitude',
          sortable: false
        },
        {
          text: 'Ticketing.longitude',
          value: 'longitude',
          sortable: false
        }
      ];

      return headersToReturn;
    }
  },
  watch: {
    $route() {}
  },
  async mounted() {
    if (this.policy.enabledServicesPolicy.length == 0) {
      this.$router.push('/');
    }

    await this.checkTicketingRequestLimit();
  },
  methods: {
    ...mapActions(['storeGetTicketingRequest', 'deleteTicketingRequestAction', 'reorganizeSlots', 'listTicketingRequestLimitAction']),
    async changeFilterDate() {
      this.$refs.rangeMenu.save(this.rangeDate);
      const filter_start_date = this.rangeDate[0];
      const filter_end_date = this.rangeDate[1];

      this.policy.ticketingServicePermissions.map(item => {
        item.filter_start_date = filter_start_date;
        item.filter_end_date = filter_end_date;
      });

      this.overlay = true;
      await this.storeGetTicketingRequest(this.policy.ticketingServicePermissions);
      this.overlay = false;
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    requestDetail(item) {
      const filtered = this.ticketing.ticketingRequestList.filter(element => element.request_id === item.request_id);
      this.request = filtered[0];
      this.request.notes = filtered[0].notes.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
      this.ticketing.viewTicketingDetailPopup = true;
    },
    createTablesArray() {
      this.requestTable = [];
      this.locationTable = [];
      this.ticketing.ticketingRequestList.forEach(item => {
        this.requestTable.push({ service_id: item.service_id, request_id: item.request_id, app_user_id: item.app_user_id, document_type: item.document_type, priority: item.priority, username: item.username, evasion_date: item.evasion_date, evasion_limit: item.evasion_limit, request_date: item.request_date, request_state: item.request_state, notes: item.notes });
        this.locationTable.push({ request_id: item.request_id, location_id: item.location_id, latitude: item.latitude, longitude: item.longitude, meteo_type: item.meteo_type, address: item.address, start_date: item.start_date, end_date: item.end_date });
      });

      this.requestTable.forEach(request => {
        this.locationTable.forEach(location => {
          if (request.request_id == location.request_id) {
            request.locations = [];
            request.locations.push(location);
          }
        });
      });
    },
    async deleteRequest(item) {
      const deletePersonalRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Annullamento richieste personali');
      const deleteNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Annullamento richieste del nodo');
      const deleteUnderNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Annullamento richieste del sotto nodo');
      const node_id = this.policy.ticketingServicePermissions[0].node_id;
      const sort_id = this.policy.ticketingServicePermissions[0].sort_id;

      if (deletePersonalRequest) {
        if (item.node_id === node_id && item.app_user_id === this.app_user.app_user_data[0].app_user_id) {
          this.canDelete = true;
        }

        if (deleteNodeRequest && item.sort_id == sort_id && item.app_user_id !== this.app_user.app_user_data[0].app_user_id) {
          this.canDelete = true;
        }

        if (deleteUnderNodeRequest && item.sort_id > sort_id) {
          this.canDelete = true;
        }

        if (deleteNodeRequest && deleteUnderNodeRequest && item.sort_id >= sort_id) {
          this.canDelete = true;
        }
      }

      if (this.canDelete) {
        this.overlay = true;
        const evasionLimit = item.evasion_limit ? moment(item.evasion_limit, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
        const isStandardRequest = !item.document_type.includes('provinciale') && !item.document_type.includes('Relazione');
        const dateToCheck = moment().nextBusinessDay().format('YYYY-MM-DD');
        const require_reorganize_slots = isStandardRequest && moment(evasionLimit, 'YYYY-MM-DD').isSameOrAfter(dateToCheck, 'day');
        const data = {
          request_id: item.request_id,
          request_state: item.request_state,
          evasion_limit: evasionLimit,
          slots_to_reorganize: require_reorganize_slots ? await this.reorganizeSlots(evasionLimit) : []
        };
        await this.deleteTicketingRequestAction(data);

        if (this.ticketing.createRequestState) {
          await this.storeGetTicketingRequest(this.policy.ticketingServicePermissions);
        }

        this.overlay = false;
      } else {
        this.$store.dispatch(
          'storeToastMessage',
          {
            text: 'permissionError',
            errObj: {
              errors: [{
                message: 'No permission for undo this request' }] },
            type: 'danger'
          },
          { root: true }
        );
      }

      this.canDelete = false;
    },
    async checkTicketingRequestLimit() {
      this.overlay = true;
      await this.listTicketingRequestLimitAction(this.app_user.crm_id);
      const findCrm = this.ticketing.ticketingRequestLimit.find(e => e.crm_id === this.app_user.crm_id);
      this.currentNum = findCrm ? findCrm.request_count : 0;
      this.maxNum = findCrm ? findCrm.request_limit : 0;
      this.overlay = false;
    },
    refreshSearch() {
      this.overlay = true;
      this.$refs.filtersRef.confirmSearchFilters();
      this.overlay = false;
    },
    isLoading(bool) {
      this.overlay = bool;
    },
    showResults(bool) {
      this.isResult = bool;
    },
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}
input::-webkit-input-placeholder {
  font-size: 2px;
  line-height: 3;
}
.address  {
  line-height: 5px;
  margin-top: 18px;
}
#locations-table {
  background: #e8f5e9;
}
#requests-table {
  white-space: nowrap !important;
}
.disable-events {
  pointer-events: none
}
</style>
