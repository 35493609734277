<template>
  <div>
    <v-overlay :value="policy.overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-navigation-drawer
      v-model="drawer.drawerVisibility"
      class="d2"
      dark
      :expand-on-hover="expandOnHover"
      mobile-breakpoint="0"
      app
      clipped
      hide-overlay
      v-bind="$attrs"
    >
      <v-toolbar>
        <v-toolbar-title class="ml-4">
          {{ $t(drawer.drawerTitle) }}
        </v-toolbar-title>
        <v-spacer />
        <span @click="openDynamicNavigationDrawer()">
          <SvgIcon
            :color1="'#000000'"
            :color2="'#FFFFFF'"
            :path="
              'M1.05263158,0 C1.63398395,3.55975658e-17 2.10526316,0.471279211 2.10526316,1.05263158 L2.10526316,10.9473684 C2.10526316,11.5287208 1.63398395,12 1.05263158,12 C0.471279211,12 4.4408921e-16,11.5287208 -8.8817842e-16,10.9473684 L1.33226763e-15,1.05263158 C1.33226763e-15,0.471279211 0.471279211,-3.55975658e-17 1.05263158,0 Z M11.2863071,0.987904934 C11.4728806,1.16028521 11.5781744,1.3944282 11.5789474,1.63793028 C11.5789474,1.88213108 11.4752093,2.11629245 11.2894186,2.28938983 L8.324,5.04 L19,5.04 C19.5522847,5.04 20,5.48771525 20,6.04 C20,6.59228475 19.5522847,7.04 19,7.04 L8.296,7.04 L11.2894186,9.81675395 C11.6764941,10.1773091 11.6749483,10.7598166 11.286322,11.1182205 C10.8984735,11.4773414 10.2706228,11.4759072 9.88354724,11.1153475 L5.12710226,6.70241231 C4.94052872,6.52644101 4.83834641,6.28869964 4.84221102,6.04161205 C4.84451869,5.80306533 4.94670714,5.57394206 5.12708739,5.40371308 L9.88353237,0.990777939 C10.2706079,0.630939852 10.8984586,0.629505648 11.2863071,0.987904934 Z'
            "
          />
        </span>
      </v-toolbar>
      <div
        v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.services')"
        class="ml-3"
      >
        <v-list>
          <v-list-item-group
            v-model="selectedItemOtherService"
            color="white"
          >
            <v-list-item
              v-for="(sideBarItem, index) in sideBarItemsArray"
              :key="index"
              @click="
                changeServiceItems(
                  sideBarItem.policy_id,
                  sideBarItem.service_id,
                  sideBarItem.service_type,
                  sideBarItem.translationPath,
                  sideBarItem.translationPathDescription
                )
              "
            >
              <v-list-item-title
                v-if="sideBarItem.service_type !== 'TICKET'"
                style="cursor: pointer"
                class="text-wrap"
              >
                {{ $t(sideBarItem.translationPath) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        v-else-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.operative_support')"
        class="ml-3"
      >
        <v-list
          v-for="sideBarItem in sideBarItemsArray"
          :key="sideBarItem.service_id"
        >
          <v-list-group
            v-if="sideBarItem.service_type === 'TICKET' && canInsertRequest || sideBarItem.service_type === 'TICKET' && canViewRequestList"
            v-model="selectedItemOperativeSupport"
            :value="false"
            color="white"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-wrap">
                {{ $t(sideBarItem.translationPath) }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-if="canInsertRequest"
              @click="
                changeServiceItems(
                  sideBarItem.policy_id,
                  sideBarItem.service_id,
                  sideBarItem.service_type,
                  sideBarItem.translationPath,
                  sideBarItem.translationPathDescription,
                  'request'
                )
              "
            >
              <span class="sidebar-items-description-submenu">{{ $t('Ticketing.request') }}</span>
            </v-list-item>
            <v-list-item
              v-if="canViewRequestList"
              @click="
                changeServiceItems(
                  sideBarItem.policy_id,
                  sideBarItem.service_id,
                  sideBarItem.service_type,
                  sideBarItem.translationPath,
                  sideBarItem.translationPathDescription,
                  'request list'
                )
              "
            >
              <!-- v-if="!mobileBreakpoints()" -->
              <span
                class="sidebar-items-description-submenu"
              >{{ $t('TicketingTable.title') }}</span>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
      <div
        v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.settings')"
        class="ml-3"
      >
        <v-list>
          <v-list-item-group
            v-model="selectedItemSettings"
            color="white"
          >
            <v-list-item
              to="/settings"
              style="cursor: pointer"
              @click="drawer.drawerVisibility = false"
            >
              <v-list-item-title>
                {{ $t('NavigationSideBar.registry') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!mobileBreakpoints()"
              to="/layers"
              style="cursor: pointer"
              @click="drawer.drawerVisibility = false"
            >
              <v-list-item-title>
                {{ $t('NavigationSideBar.layer') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div
        v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.support')"
        class="ml-3"
      >
        <v-list>
          <v-list-item-group
            v-model="selectedItemSupport"
            color="white"
          >
            <v-list-item
              v-if="app_user.customerManuals.length > 0"
              to="/manuals"
              style="cursor: pointer"
              @click="openDynamicNavigationDrawer()"
            >
              <!-- @click="drawer.drawerVisibility = false" -->
              <v-list-item-title>
                {{ $t('NavigationSideBar.manuals') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="mobileBreakpoints()"
              style="cursor: pointer"
            >
              <v-list-item-title @click="downloadManual('disclaimer/Disclaimer.pdf')">
                Disclaimer
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else
              to="/disclaimer"
              style="cursor: pointer"
              @click="openDynamicNavigationDrawer()"
            >
              <v-list-item-title>
                Disclaimer
              </v-list-item-title>
            </v-list-item>
            <span v-if="app_user.listAppUserEnabledServices.some(item => item.service_type === 'TICKET')">
              <v-list-item
                v-if="mobileBreakpoints()"
                style="cursor: pointer"
              >
                <v-list-item-title @click="downloadManual('disclaimer/Disclaimer_Meteotrigger_2.0.pdf')">
                  Disclaimer Meteotrigger
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                to="/disclaimer-meteotrigger"
                style="cursor: pointer"
                @click="openDynamicNavigationDrawer()"
              >
                <v-list-item-title>
                  Disclaimer Meteotrigger
                </v-list-item-title>
              </v-list-item>
            </span>
            <v-list-item
              to="/contacts"
              style="cursor: pointer"
              @click="openDynamicNavigationDrawer()"
            >
              <v-list-item-title>
                {{ $t('NavigationSideBar.contacts') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { nestHierarchyItems, getFormattedDate } from '@/utils.js';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';

export default {
  components: {
    SvgIcon
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mini: true,
      canInsertRequest: false,
      canViewRequestList: false,
      selectedItemOtherService: null,
      selectedItemOperativeSupport: null,
      selectedItemSettings: null,
      selectedItemSupport: null
    };
  },
  computed: {
    ...mapState(['policy', 'drawer', 'ticketing', 'app_user', 'map']),
    sideBarItemsArray() {
      const sideBarItemsArray = [];

      if (this.policy.enabledServicesPolicy.length != 0) {
        const enabledServices = ['URL', 'DOC', 'TICKET', 'ALERT_EXP', 'REPORTS', 'REPORT_HUB'];

        enabledServices.forEach(item => {
          if (item === 'TICKET') {
            this.policy.ticketingServicePermissions = this.policy.enabledServicesPolicy[item].permissions;

            if (this.policy.enabledServicesPolicy[item].enabled_service[0] != undefined) {
              this.ticketing.app_user_ticket_id = this.policy.enabledServicesPolicy[item].enabled_service[0].app_user_ticket_id;
            }

            this.policy.ticketingServicePermissions.forEach(item => {
              item.app_user_id = this.app_user.app_user_id;

              if (item.permission_name === 'Inserimento richieste') {
                this.canInsertRequest = true;
              }

              if (item.permission_name === 'Visualizza lista richieste personali') {
                this.canViewRequestList = true;
              }
            });
            this.policy.enabledServicesPolicy[item].enabled_service.map(e => {
              sideBarItemsArray.push({
                translationPath: `ServiceCard.${e.service_id}.title`,
                translationPathDescription: `ServiceCard.${e.service_id}.description`,
                app_user_id: e.app_user_id,
                service_id: e.service_id,
                policy_id: e.policy_id,
                service_type: e.service_type
              });
            });
          } else {
            this.policy.enabledServicesPolicy[item].map(e => {
              sideBarItemsArray.push({
                translationPath: `ServiceCard.${e.service_id}.title`,
                translationPathDescription: `ServiceCard.${e.service_id}.description`,
                app_user_id: e.app_user_id,
                service_id: e.service_id,
                policy_id: e.policy_id,
                service_type: e.service_type
              });
            });
          }
        });
      }

      return sideBarItemsArray;
    }
  },
  methods: {
    ...mapActions(['storeAppUserPolicy', 'removeLayer']),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          break;
      }
    },
    openDynamicNavigationDrawer() {
      this.drawer.drawerVisibility = null;
      this.drawer.drawerTitle = '';

      this.drawer.drawerVisibility = false;
    },
    async changeServiceItems(
      policy_id,
      service_id,
      service_type,
      translationPath,
      translationPathDescription,
      ticketingType
    ) {
      this.policy.translationPath = translationPath;
      this.policy.translationPathDescription = translationPathDescription;

      if (service_type !== 'ALERT EX-POST' && service_type !== 'REPORTS') {
        const params = {
          policy_id: policy_id,
          service_id: service_id,
          service_type: service_type
        };

        this.policy.overlay = true;
        await this.storeAppUserPolicy(params);
        this.policy.appUserPolicy.forEach(policyItem => {
          if (policyItem.URL.length > 0) {
            this.policy.hierarchyItems = nestHierarchyItems(policyItem.URL);
            this.$router.push({
              name: 'Services',
              params: { id: service_id }
            });
          } else {
            if (policyItem.DOC.length > 0) {
              this.policy.hierarchyItems = nestHierarchyItems(policyItem.DOC);
              this.$router.push({
                name: 'Services',
                params: { id: service_id }
              });
            } else {
              if (policyItem.TICKET.length > 0) {
                this.ticketing.appUserPolicyTicketing = policyItem.TICKET;

                if (ticketingType === 'request') {
                  this.$router.push({
                    name: 'TicketingRequest',
                    params: { id: service_id }
                  });
                } else {
                  this.$router.push({
                    name: 'TicketingRequestList',
                    params: { id: service_id }
                  });
                }
              } else {
                if (policyItem.REPORT_HUB.length > 0) {
                  this.$router.push({
                    name: 'Report-Hub',
                    params: { id: service_id }
                  });
                }
              }
            }
          }

          this.policy.overlay = false;
        });
      } else {
        if (service_type === 'ALERT EX-POST') {
          this.$router.push({
            name: 'Alert Ex-Post'
          });
          this.drawer.workspaceSidebar = true;
          this.drawer.disableWorkspaceSidebar = false;
          this.map.OLmap.getLayers().getArray().forEach(layer => {
            if (layer.values_.service_id !== undefined) {
              this.removeLayer(layer.values_.layer_name);
            }
          });
        }

        if (service_type === 'REPORTS') {
          this.$router.push({
            name: 'Reports'
          });
          this.drawer.workspaceSidebar = true;
          this.drawer.disableWorkspaceSidebar = false;
          this.map.OLmap.getLayers().getArray().forEach(layer => {
            if (layer.values_.service_id !== undefined) {
              this.removeLayer(layer.values_.layer_name);
            }
          });
        }
      }

      this.drawer.drawerVisibility = false;

      if (ticketingType === 'request' || ticketingType === 'request list') {
        this.$gtag.event('Sottoservizio di meteotrigger', {
          event_category: service_type,
          event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${this.$t(translationPath)} - ${ticketingType}`
        });
      } else {
        this.$gtag.event('Accesso servizio aggiuntivo', {
          event_category: service_type,
          event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: this.$t(translationPath)
        });
      }
    },
    async downloadManual(path) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: path
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer--open.d2 {
  left: 85px;
  box-shadow: 0 0 0 0;
}
@media (max-device-width:599px){
  .v-navigation-drawer--open.d2 {
    left: 20px;
  }
}
.sidebar-items-description {
  font-size: 14px;
  color: #b2b2b2;
  text-align: left;
}
.sidebar-items-description-submenu {
  font-size: 12px;
  color: #b2b2b2;
  text-align: left;
  margin-left: 5%;
}
.v-list /deep/ {
  padding: 0;
}
</style>
