<template>
  <v-flex
    offset-2
    offset-lg-1
    class="flex-margin"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title
        class="card-title v-card-scroll"
        color="black"
      >
        {{ $t(`ServiceCard.${$route.params.id}.title`) }}
      </v-card-title>

      <v-tabs
        v-model="selectedTab"
        color="green"
        active-class="white"
        background-color="#f4f3f3"
        grow
      >
        <v-tab
          v-for="(type, index) in reportType"
          :key="index"
        >
          {{ type.label }}
        </v-tab>
      </v-tabs>

      <v-row
        v-if="selectedTab > 0"
        class="ms-1 mt-3 me-5"
      >
        <v-col cols="6">
          <v-menu
            ref="filterReportHubMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="rangeDateText"
                multiple
                chips
                small-chips
                label="Filter range date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                class="shrink"
                clearable
                @click:clear="closeDateRange()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              no-title
              range
              scrollable
              :min="rangeDate[0]"
              :max="moment().format('YYYY-MM-DD')"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="changeFilterDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            class="shrink"
            clearable
            prepend-icon="mdi-magnify"
            :label="$t('AlertExPost.search')"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(type, index) in reportType"
          :key="index"
        >
          <v-card>
            <v-data-table
              :headers="tabHeaders"
              :items="filteredItems"
              :search="search"
              sort-by="reportDate"
              :sort-desc="true"
              class="elevation-1"
              :footer-props="{
                itemsPerPageAllText: $t('Table.all'),
                itemsPerPageText: $t('Table.rowsPerPage'),
                pageText: `{0}-{1} ${$t('Table.of')} {2}`
              }"
              :no-data-text="$t('Table.noData')"
            >
              <template v-slot:[`item.reportDate`]="{item}">
                {{ item.reportDate || '-' }}
              </template>
              <!-- <template v-slot:top>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('AlertExPost.search')"
                  class="mx-5 mb-5 mt-0 pt-5"
                  single-line
                  hide-details
                />
              </template> -->
              <template v-slot:[`item.view`]="{ item }">
                <v-btn
                  class="me-3"
                  small
                  outlined
                  color="success"
                  dark
                  @click="dispatchDownloadItem(item.s3_path)"
                >
                  PDF
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import moment from 'moment-business-days';

export default {
  data: () => ({
    overlay: false,
    rangeMenu: false,
    serviceName: '',
    selectedTab: {},
    folders: [],
    headers: [],
    rangeDate: [],
    reportType: [],
    itemsArray: [],
    search: null
  }),

  computed: {
    ...mapState(['app_user', 'policy', 'file_transfer']),
    tabHeaders() {
      return this.headers.filter(header => header.value !== 'reportType' || this.selectedTab === 0);
    },
    rangeDateText() {
      const start_date = this.rangeDate[0] ? moment(this.rangeDate[0]).format('YYYY-MM-DD') : '';
      const end_date = this.rangeDate[1] ? moment(this.rangeDate[1]).format('YYYY-MM-DD') : '';

      return this.rangeDate.length ? `${start_date} - ${end_date}` : '';
    },
    filteredItems() {
      return this.rangeDate.length < 2
        ? this.itemsArray
        : this.itemsArray.filter(e => {
          const start = moment(this.rangeDate[0]).format('YYYY-MM-DD');
          const end = moment(this.rangeDate[1]).format('YYYY-MM-DD');

          return moment(e.reportDate, 'YYYY-MM-DD').isSameOrAfter(start, 'day') && moment(e.reportDate, 'YYYY-MM-DD').isSameOrBefore(end, 'day');
        });
    }
  },

  watch: {
    async $route(to, from) {
      if (from.name === 'Report-Hub' && from.name === to.name) {
        this.overlay = false;
        this.rangeMenu = false;
        this.serviceName = '';
        this.selectedTab = {};
        this.folders = [];
        this.headers = [];
        this.rangeDate = [];
        this.reportType = [];
        this.itemsArray = [];
        this.search = null;
        await this.fetchData();
      }
    },
    selectedTab(newTab) {
      if (newTab === 0) {
        this.itemsArray = [];
        this.folders.forEach(folder => {
          const folder_name = `REPORT-HUB/${this.app_user.crm_id}/${this.serviceName}/${folder}/`;
          const items = this.policy.folderObjects.filter(str => str.startsWith(folder_name));
          const reports = items.map(report_path => ({
            reportType: folder,
            ReportName: report_path.replace(folder_name, ''),
            reportDate: this.extractDate(report_path.replace(folder_name, '')),
            s3_path: report_path
          }));
          const timesStamps = reports.map(e => moment(e.reportDate).unix());
          const max = Math.max(...timesStamps.filter(value => !isNaN(value)));
          this.itemsArray.push(...reports.filter(e => moment(e.reportDate).unix() === max));
        });
      } else {
        const folder_name = `REPORT-HUB/${this.app_user.crm_id}/${this.serviceName}/${this.reportType[newTab]?.type}/`;
        const items = this.policy.folderObjects.filter(str => str.startsWith(folder_name));
        this.itemsArray = items.map(e => ({
          reportType: this.reportType[newTab]?.type,
          ReportName: e.replace(folder_name, ''),
          reportDate: this.extractDate(e.replace(folder_name, '')),
          s3_path: e
        }));
      }
    }
  },

  async created () {
    await this.fetchData();
  },

  methods: {
    ...mapActions(['storeFolderObjects', 'downloadItem']),
    async fetchData() {
      this.overlay = true;

      const reportHubServiceSelected = this.policy.appUserPolicy[0].REPORT_HUB.filter(e => e.service_id === this.$route.params.id);
      const params = {
        crm_id: this.app_user.crm_id,
        service_id: this.$route.params.id,
        folders: reportHubServiceSelected.map(e => e.node_name)
      };
      await this.storeFolderObjects(params);

      this.generateTabs();

      this.headers = [
        { text: this.$t('Reports.type'), value: 'reportType', sortable: true },
        { text: this.$t('Reports.name'), value: 'ReportName', sortable: true },
        { text: this.$t('Reports.date'), value: 'reportDate', sortable: true },
        { text: this.$t('Reports.view'), value: 'view', align: 'end', sortable: false }
      ];

      this.overlay = false;
    },
    generateTabs() {
      this.serviceName = this.policy.folderObjects.map(path => path.split('/')[2])[0];
      this.folders = [...new Set(this.policy.folderObjects.map(path => path.split('/')[3]))];
      this.policy.appUserPolicy.forEach(policyItem => {
        if (policyItem.REPORT_HUB.length > 0) {
          this.reportType = [
            { type: 'LatestReports', label: this.$t('Reports.latestReports') },
            ...policyItem.REPORT_HUB.map(e => {
              if (this.folders.includes(e.node_name)) {
                return { type: e.node_name, label: this.$t(`ServiceCard.${e.node_id}.title`) };
              }
            }).filter(Boolean)
          ];
        }
      });
    },
    async dispatchDownloadItem(s3_path) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: s3_path
      };
      await this.downloadItem(params);
      window.open(this.file_transfer.downloadLink);
    },
    extractDate(str) {
      const datePattern = /\d{4}-\d{2}-\d{2}_\d{4}\.pdf$/;
      const match = str.match(datePattern);

      if (match) {
        return this.moment(match[0], 'YYYY-MM-DD_HHmm').format('YYYY-MM-DD HH:mm');
      }

      return null;
    },
    changeFilterDate() {
      this.$refs.filterReportHubMenu.save(this.rangeDate);
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.rangeDate = [];
      this.$refs.filterReportHubMenu.save([]);
    }
  }
};
</script>

<style scoped>
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.card {
  margin-right:5%;
}

@media (max-device-width: 599px) {
  .flex-margin {
    margin-bottom: 2%;
  }
}

@media (min-device-width: 600px) {
  .flex-margin {
    margin-left: calc(100px + 2%);
  }
}

</style>
