<template>
  <div>
    <v-flex
      offset-2
      offset-lg1
      offset-xl1
      class="flex-margin"
    >
      <v-overlay :value="policy.overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div>
        <div class="pa-4 pb-0">
          <div
            v-if="mobileBreakpoints()"
            class="d-flex"
            style="justify-content: end;"
          >
            <v-icon
              class="text-right"
              color="red"
              size="28"
              @click="$router.push('/')"
            >
              mdi-close
            </v-icon>
          </div>
          <h1 class="title-color">
            {{ $t(policy.translationPath) }}
          </h1>
          <h4 class="description-color">
            {{ $t(policy.translationPathDescription) }}
          </h4>
        </div>
        <div
          v-for="hierarchyItem in policy.hierarchyItems"
          :key="hierarchyItem.node_id"
          class="mt-2"
        >
          <hierarchy
            :father="hierarchyItem.father_id"
            :label="`ServiceCard.${hierarchyItem.node_id}.title`"
            :description="hierarchyItem.node_description"
            :link="hierarchyItem.external_link"
            :s3="hierarchyItem.path_s3_bucket"
            :children="hierarchyItem.children"
          />
        </div>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hierarchy from '@/components/utils/Hierarchy.vue';

export default {
  components: {
    Hierarchy
  },
  data() {
    return {
      search: '',
      hierarchyItems: [],
      hierarchyItemsLinear: [],
      app_user_id: ''
    };
  },
  computed: {
    ...mapState(['policy'])
  },
  watch: {
    $route() {}
  },
  mounted() {
    if (this.policy.enabledServicesPolicy.length == 0) {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions([]),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.title-color {
  color: #0ca344;
}
.description-color {
  color: #3d3d3d;
}
@media (min-device-width:280px) and (max-device-width:1023px) {
  .title-color {
    font-size: 24px !important;
  }
  .description-color {
    font-size: 18px !important;
  }
}
.viewMargin {
  margin-left: 6em !important;
}
</style>
