<template>
  <div>
    <v-flex
      offset-2
      offset-lg1
      class="flex-margin"
    >
      <div
        v-if="mobileBreakpoints()"
        class="d-flex mb-2"
        style="justify-content: end;"
      >
        <v-icon
          class="text-right"
          color="red"
          size="28"
          @click="$router.push('/')"
        >
          mdi-close
        </v-icon>
      </div>
      <v-card>
        <v-card-title
          class="card-title v-card-scroll"
          color="black"
        >
          {{ $t('NavigationSideBar.contacts') }}
        </v-card-title>
        <v-row
          class="ma-8"
          no-gutters
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              class="pa-2"
              elevation="0"
            >
              <h3>Radarmeteo S.r.l.</h3>
              <p>
                via IV Novembre, 117<br>
                35020 Due Carrare (PD)
              </p>
              <p>
                <b class="text-overline">Tel.</b><br>
                (+39) 049 9125902
              </p>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              class="pa-2"
              elevation="0"
            >
              <p>
                <span class="text-overline">email</span><br>
                <a href="mailto:assistenza@radarmeteo.com">assistenza@radarmeteo.com</a>
              </p>
              <p>
                <span class="text-overline">posta certificata</span><br>
                <a href="mailto:pec@pec.radarmeteo.com">pec@pec.radarmeteo.com</a>
              </p>
              <p>
                <span class="text-overline">social</span><br>
                <a
                  href="https://www.linkedin.com/company/radarmeteo-srl/"
                  target="_blank"
                >
                  <v-icon
                    color="grey lighten-1"
                    large
                  >
                    mdi-linkedin
                  </v-icon>
                </a>
                <a
                  href="https://www.facebook.com/Radarmeteo.srl"
                  target="_blank"
                >
                  <v-icon
                    class="mx-8"
                    color="grey lighten-1"
                    large
                  >
                    mdi-facebook
                  </v-icon>
                </a>
                <a
                  href="https://twitter.com/radarmeteosrl"
                  target="_blank"
                >
                  <v-icon
                    color="grey lighten-1"
                    large
                  >
                    mdi-twitter
                  </v-icon>
                </a>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState([])
  },
  async created() {
  },
  methods: {
    ...mapActions([]),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
a:link {
  text-decoration: none;
  color: black;
}
.text-overline {
  color: #0da344;
}
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}

.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.card {
  margin-right:5%;
}
</style>
